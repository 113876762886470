import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"


import PostLink from "../components/post-link"
import PostCard from "../components/post-link-cards"
import PostImage from "../components/post-image"
import Content from "../components/post-content"

const ProductPage = ({data: {allMarkdownRemark: { edges }, },}) => {


  const categories = edges
    .filter(edge => edge.node.frontmatter.catagory==="Produkte") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostLink key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

    const pictures = edges
    .filter(edge => edge.node.frontmatter.catagory==="Impressionen") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-90"> <PostImage key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )

    const standorte = edges
    .filter(edge => edge.node.frontmatter.catagory==="Standorte") // You can filter your posts based on some criteria
    .map(edge => <div class="w-full rounded hover:opacity-900"> <PostCard key={Math.random().toString(36).substr(2, 9)} post={edge.node}  /></div> )
     
    const welcomeText = edges
    .filter(edge => edge.node.frontmatter.catagory==="Content" && edge.node.frontmatter.page==="ankauf") // You can filter your posts based on some criteria
    .map(edge => <div class="rounded hover:opacity-900"> <Content key={Math.random().toString(36).substr(2, 9)} post={edge.node} /></div> )

   
  return (
    <Layout>
      <Helmet>
        <title>Kontakt | Schatzkammer Lübeck</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Hofverkauf und Schatzcafe Wakenitzstraße 12-14, 23564 Lübeck | Ladengeschäft an d. Untertrave 104, 23552 Lübeck" />
        <meta name="author" content="Schatzkammer Lübeck" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <section class=" py-8 ">
      <h1 class="font-serif w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" >Kontakt</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto skBrown w-64 my-0 py-0 opacity-25 rounded-t"></div>
            </div>  
<div class="relative flex items-top justify-center min-h-screen dark:bg-gray-900 sm:items-center sm:pt-0">
 
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div class="mt-8 overflow-hidden">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="p-6 mr-2 skBrown dark:bg-gray-800 sm:rounded-lg">
                        <h1 class="font-serif text-4xl sm:text-5xl text-white  dark:text-white font-extrabold tracking-tight">
                            Schatzkammer
                        </h1>
                        <p class="text-normal text-lg sm:text-2xl font-medium text-white  dark:text-gray-400 mt-2">
                            Hof- und Ladenverkauf
                        </p>

                        <div class="flex items-center mt-8 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-white ">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              Hofverkauf Wakenitzstraße 14, 23564 Lübeck
                            </div>
                        </div>
                        
                        <div class="flex items-center mt-8 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              Ladengeschäft An d. Untertrave 104, 23552 Lübeck
                            </div>
                        </div>

                        <div class="flex items-center mt-4 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              +49 1705 1326 97
                            </div>
                        </div>

                        <div class="flex items-center mt-4 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 30 30" class="w-8 h-8 text-white ">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.0" d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              +49 1705 1326 97
                            </div>
                        </div>
                    


                    </div>
                    <div class="p-6 mr-2 skBrown sm:rounded-lg">
                        <h1 class="font-serif text-4xl sm:text-5xl text-white dark:text-white font-extrabold tracking-tight">
                          Schatz Café
                        </h1>
                        <p class="text-normal text-lg sm:text-2xl font-medium text-white  dark:text-gray-400 mt-2">
                            Café
                        </p>

                        <div class="flex items-center mt-8 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-white ">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              Wakenitzstraße 12, 23564 Lübeck
                            </div>
                        </div>

                        <div class="flex items-center mt-4 text-white  dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-white ">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                              +49 1737 9395 55
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </section>



</Layout>
)


}

export default ProductPage
export const pageQuery = graphql`
query {
site {
  siteMetadata {
    title
    description
  }
}
allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
  edges {
    node {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        catagory
        page
        metaDescription
      }
      html
    }
  }
}
slideShow: allFile(
  sort: {fields: base, order: ASC}
  filter: {base: {regex: "/DSC0/"}}
) {
  edges {
    node {
      id
      relativePath
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: {fit: COVER}
          height: 300
        )
      }
    }
  }
}
}
`
